import React from 'react';
import { useDispatch } from 'react-redux';
import { mostrarToast } from './ToastFunciones';
import { nombreDePais } from './PaisesFunciones.js';

const Ciudad = (resultadoCiudades) => {

    const dispatch = useDispatch();

    const guardarLatLon = (lat, lon, pais, ciudad) => {
        let coordBuscadas = `lat=${lat}&lon=${lon}`;
        let nombrePais = nombreDePais(pais);
        let host = window.location.href;
        if (host.indexOf("www") === -1 ) {
            // console.log("viene sin www");
            host = "https://clima.supportmc.com/static/js";
        } else {
            // console.log("viene con www");
            host = "https://www.clima.supportmc.com/static/js";
        }

        // fetch(`http://localhost:8888/FetchAPISenPHP/conexion_api_localhost.php?${coordBuscadas}`)
        fetch(`${host}/conexion_api.php?${coordBuscadas}`)
            .then((r) => {
                if ((r.status >= 200 & r.status <= 299) | r.status === 404) {
                    return r.json();
                } else {
                    throw new Error(`Hubo un error conectando a la API: ${r.status} - ${r.statusText}`);
                }
            })
            .then((climaCiudad) => {
                dispatch({ type: "BUSCAR_CIUDAD", payload: climaCiudad, pais: nombrePais, ciudad: ciudad});
            })
            .catch((error) => {
                mostrarToast(`estado`, `liveToast`, `Parece que hubo un error, vuelva más tarde.`);
                console.log(error);
            });

    };

    return (
        <>
            <tr onClick={() => guardarLatLon(`${resultadoCiudades.lat}`, `${resultadoCiudades.lon}`, `${resultadoCiudades.country}`, `${resultadoCiudades.name}`)}><td style={{ width: '50%' }}>{resultadoCiudades.name}</td><td>{nombreDePais(resultadoCiudades.country)}</td></tr>
        </>
    )
}

export default Ciudad
