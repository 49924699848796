import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mostrarToast, cerrarToast, cerrarToastAutom } from './ToastFunciones';


const Buscador = () => {

    const ciudad = useRef("");
    const dispatch = useDispatch();

    let coordInicial = useSelector(state => state.coordBuscada);
    let host = window.location.href;
    // console.log(host);
    if (host.indexOf("www") === -1 ) {
        // console.log("viene sin www");
        host = "https://clima.supportmc.com/static/js";
    } else {
        // console.log("viene con www");
        host = "https://www.clima.supportmc.com/static/js";
    }

    useEffect(() => {
        // fetch(`http://localhost:8888/FetchAPISenPHP/conexion_api_localhost.php?${coordInicial}`)
        fetch(`${host}/conexion_api.php?${coordInicial}`)
            .then((r) => {
                if ((r.status >= 200 & r.status <= 299) | r.status === 404) {
                    return r.json();
                } else {
                    throw new Error(`Hubo un error conectando a la API: ${r.status} - ${r.statusText}`);
                }
            })
            .then((climaMvd) => {
                dispatch({ type: "CARGAR_DEFAULT", payload: climaMvd });
            })
            .catch((error) => {
                mostrarToast(`estado`, `liveToast`, `Parece que hubo un error, vuelva más tarde.`);
                console.log(error);
            })
            ;
    }, [])

    const buscarCiudad = e => {
        let ciudadBuscada = ciudad.current.value;
        if (ciudadBuscada !== "") {
            // fetch(`http://localhost:8888/FetchAPISenPHP/conexion_api_localhost.php?q=${ciudadBuscada}`)
            fetch(`${host}/conexion_api.php?q=${ciudadBuscada}`)
                .then((response) => {
                    if ((response.status >= 200 & response.status <= 299) | response.status === 404) {
                        return response.json();
                    } else {
                        throw new Error(`Hubo un error conectando a la API: ${response.status} - ${response.statusText}`);
                    }
                })
                .then((jsonData) => {
                    if (jsonData.length > 0) {
                        dispatch({ type: "BUSCAR_COORDENADAS", payload: jsonData });
                    // si hay resultado ocultar el Toast
                    // cerrarToast(idEstado, idToast)
                        cerrarToast(`estado`, `liveToast`);
                    } else {
                        let ciudadMayusculas = ciudadBuscada.toUpperCase();
                        // si no existe la ciudad, mostrar el Toast con la ciudad buscada
                        // mostrarToast(idEstado, idToast, mensaje, idInput)
                        mostrarToast(`estado`, `liveToast`, `No existe una ciudad con el nombre ${ciudadMayusculas}.`, `ciudad`);
                        // cerrar el Toast automáticamente después de 3 segundos
                        // cerrarToastAutom(milisegundos, idEstado, idToast)
                        cerrarToastAutom(`3000`, `estado`, `liveToast`);
                    }
                })
                .catch((error) => {
                    mostrarToast(`estado`, `liveToast`, `Parece que hubo un error, vuelva más tarde.`);
                    console.log(error);
                });
        } else {
            // si no se ingresó nada en la búsqueda, mostrar el Toast pidiendo que se ingrese ciudad
            // mostrarToast(idEstado, idToast, mensaje, idInput)
            mostrarToast(`estado`, `liveToast`, `Debe ingresar una ciudad.`, `ciudad`);
            // cerrar el Toast automáticamente después de 3 segundos
            // cerrarToastAutom(milisegundos, idEstado, idToast)
            cerrarToastAutom(`3000`, `estado`, `liveToast`);
        }
    }

    const apretarEnter = e => {
        if (e.key === "Enter") {
            buscarCiudad();
        }
    };

    return (
        <div className="row m-2">
            <div className="col mt-5 p-5 bg-light border rounded">
                <div className="input-group">
                    <input type="text" ref={ciudad} id="ciudad" className="form-control" placeholder="Ingrese ciudad"
                        aria-label="Ingrese ciudad" aria-describedby="button-addon" onKeyPress={apretarEnter} />
                    <button className="btn btn-dark" type="button" id="button-addon" onClick={buscarCiudad}>Buscar</button>
                </div>
            </div>
        </div>
    )
}

export default Buscador
