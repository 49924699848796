import nueva from "../images/luna/nueva.png";
import cuarto_creciente from "../images/luna/cuarto_creciente.png";
import creciente_concava from "../images/luna/creciente_concava.png";
import creciente_gibosa from "../images/luna/creciente_gibosa.png";
import llena from "../images/luna/llena.png";
import menguante_gibosa from "../images/luna/menguante_gibosa.png";
import cuarto_menguante from "../images/luna/cuarto_menguante.png";
import menguante_concava from "../images/luna/menguante_concava.png";




// export function epochAHora(epoch) {
//     var fecha = new Date(epoch * 1000);
//     let horaLocal = fecha.toLocaleString();
//     let horaGMT = fecha.toGMTString();
//     let hora1 = horaLocal.slice(-11, -6) + " " + horaLocal.slice(-2);
//     // let hora1 = horaGMT.slice(-12, -7) + " " + horaGMT.slice(-3);
//     return hora1;
// }

export function calcDia(epoch, offset) {
    let fecha = new Date(epoch * 1000);
    let utc = fecha.getTime() + (fecha.getTimezoneOffset() * 60000);
    let nd = new Date(utc + (1000 * offset));
    let dia = nd.toLocaleString('es-UY', { weekday: 'long' });
    let diaCiudad = dia.charAt(0).toUpperCase() + dia.slice(1);
    return diaCiudad;
}

export function calcTime(epoch, offset) {
    let fecha = new Date(epoch * 1000);
    let utc = fecha.getTime() + (fecha.getTimezoneOffset() * 60000);
    let nd = new Date(utc + (1000 * offset));
    let hora = nd.toLocaleString('en-GB', { hour12: false }).toUpperCase();
    // let horaCiudad = hora.slice(-11, -6) + " " + hora.slice(-2);
    let horaCiudad = hora.slice(-8, -3); //para hour12 false
    // let horaCiudad = hora.slice(-11, -6) + " " + hora.slice(-2); //para hour12 true
    return horaCiudad;
}

export function calcDiaNum(epoch, offset) {
    let fecha = new Date(epoch * 1000);
    let utc = fecha.getTime() + (fecha.getTimezoneOffset() * 60000);
    let nd = new Date(utc + (1000 * offset));
    let hora = nd.toLocaleString('en-GB', { hour12: false }).toUpperCase();
    let diaNum = hora.slice(-20, -18); //dia en numero
    return diaNum;
}

export function gradosAPuntosCardinales(grados) {
    var val = Math.floor((grados / 22.5) + 0.5);
    var arr = ["↓ N", "↙ NNE", "↙ NE", "↙ ENE", "← E", "↖ ESE", "↖ SE", "↖ SSE", "↑ S", "↗ SSO", "↗ SO", "↗ OSO", "→ O", "↘ ONO", "↘ NO", "↘ NNO"];
    return arr[(val % 16)];
}

export function uvi(indice) {
    let uvi = Math.ceil(indice);
    if (uvi === 0 || uvi === 1 || uvi === 2) {
        return uvi + " Bajo";
    }
    if (uvi === 3 || uvi === 4 || uvi === 5) {
        return uvi + " Moderado";
    }
    if (uvi === 6 || uvi === 7) {
        return uvi + " Alto";
    }
    if (uvi === 8 || uvi === 9 || uvi === 10) {
        return uvi + " Muy alto";
    }
    if (uvi === 11) {
        return uvi + " Extremo";
    }
}

export function fasesDeLaLuna(fase) {
    let b = Math.round(fase * 8); // scale fraction from 0-8 and round

    if (b >= 8) b = 0; // 0 and 8 are the same so turn 8 into 0

    switch (b) {
        case 0:
            return {
                a: nueva,
                m: "Nueva"
            }
        case 1:
            return {
                a: creciente_concava,
                m: 'Creciente cóncava'
            }
        case 2:
            return {
                a: cuarto_creciente,
                m: "Cuarto creciente"
            }
        case 3:
            return {
                a: creciente_gibosa,
                m: "Creciente gibosa"
            }
        case 4:
            return {
                a: llena,
                m: "Llena"
            }
        case 5:
            return {
                a: menguante_gibosa,
                m: "Menguante gibosa"
            }
        case 6:
            return {
                a: cuarto_menguante,
                m: "Cuarto menguante"
            }
        case 7:
            return {
                a: menguante_concava,
                m: "Menguante cóncava"
            }
        default:
            return "";
    }
}