import React from 'react';
import { useSelector } from 'react-redux';
import { calcDia, calcTime, gradosAPuntosCardinales, uvi, fasesDeLaLuna } from './EpochFunciones.js';

const Hoy = (resultadoClima) => {

    let ciudadBuscada = useSelector(state => state.ciudadBuscada);
    let pais = useSelector(state => state.pais);
    var timezone = useSelector(state => state.timezone);

    let climaHoy = resultadoClima.daily[0];

    let iconoHoy = `https://openweathermap.org/img/w/${climaHoy.weather[0].icon}.png`;
    let descripcionHoy = climaHoy.weather[0].description.charAt(0).toUpperCase() + climaHoy.weather[0].description.slice(1);

    let climaActual = resultadoClima.current;
    let hoy = calcDia(climaActual.dt, timezone);

    let iconoActual = `https://openweathermap.org/img/w/${climaActual.weather[0].icon}.png`;
    let descripcionActual = climaActual.weather[0].description.charAt(0).toUpperCase() + climaActual.weather[0].description.slice(1);

    let amanecer = calcTime(climaActual.sunrise, timezone);
    let atardecer = calcTime(climaActual.sunset, timezone);
    let horaLocal = calcTime(climaActual.dt, timezone);

    let direccionVientoActual = gradosAPuntosCardinales(climaActual.wind_deg);
    let direccionVientoHoy = gradosAPuntosCardinales(climaHoy.wind_deg);

    let indiceUVActual = uvi(climaActual.uvi);
    let indiceUVHoy = uvi(climaHoy.uvi);

    let salidaLuna = calcTime(climaHoy.moonrise, timezone);
    let ocultaLuna = calcTime(climaHoy.moonset, timezone);
    let luna = fasesDeLaLuna(climaHoy.moon_phase);

    return (
        <>
            <div className="row mt-5">
                <div className="col">
                    <h1 className="text-start" id="ciudadEncontrada">{ciudadBuscada} - {pais}</h1>
                </div>
            </div>
            <div className="col-12 col-md-4 mt-2 me-3 p-3 border border-dark rounded">
                <h4>Hoy {hoy}</h4>
                <div className="row">
                    <div className="col-6">
                        <img className="mx-auto d-block" src={`${iconoActual}`} width="100px" height="auto" alt="" />
                    </div>
                    <div className="col-6">
                        <img className="mx-auto d-block" src={`${iconoHoy}`} width="100px" height="auto" alt="" />
                    </div>
                </div>
                <hr />

                Ahora: <strong>{descripcionActual}</strong><br />
                Hora local: <strong>{horaLocal}</strong><br />
                <table className="table table-light table-striped table-bordered">
                    <tbody>
                        <tr><td style={{ width: '50%' }}>Temperatura</td><td>{Math.round(climaActual.temp)} °c</td></tr>
                        <tr><td>Sensación térmica</td><td>{Math.round(climaActual.feels_like)} °c</td></tr>
                        <tr><td>Viento</td><td>{Math.round(climaActual.wind_speed * 3.6)} km/h {direccionVientoActual}</td></tr>
                        { climaActual.wind_gust ? <tr><td>Ráfagas</td><td>{Math.round(climaActual.wind_gust * 3.6)} km/h</td></tr> :  <tr><td>Ráfagas</td><td>No disponible</td></tr> }
                        <tr><td>Humedad</td><td>{Math.round(climaActual.humidity)} %</td></tr>
                        <tr><td>Presión</td><td>{Math.round(climaActual.pressure)} hPa</td></tr>
                        <tr><td>Indice UV</td><td>{indiceUVActual}</td></tr>
                        <tr><td>Visibilidad</td><td>{Math.round(climaActual.visibility / 1000)} km</td></tr>
                        <tr><td>Punto de rocío</td><td>{Math.round(climaActual.dew_point)} °c</td></tr>
                    </tbody>
                </table>
                
                <hr />
                Hoy: <strong>{descripcionHoy}</strong><br />
                Temperatura:<br />
                <table className="table table-light table-striped table-bordered">
                    <tbody>
                        <tr><td style={{ width: '50%' }}>Min / Max</td><td>{Math.round(climaHoy.temp.min)} / {Math.round(climaHoy.temp.max)} °c</td></tr>
                        <tr><td>Viento</td><td>{Math.round(climaHoy.wind_speed * 3.6)} km/h {direccionVientoHoy}</td></tr>
                        <tr><td>Ráfagas</td><td>{Math.round(climaHoy.wind_gust * 3.6)} km/h</td></tr>
                        <tr><td>Humedad</td><td>{Math.round(climaHoy.humidity)} %</td></tr>
                        <tr><td>Presión</td><td>{Math.round(climaHoy.pressure)} hPa</td></tr>
                        <tr><td>Chance de lluvia</td><td>{Math.ceil(climaHoy.pop)} %</td></tr>
                        <tr><td>Indice UV</td><td>{indiceUVHoy}</td></tr>
                        <tr><td>Punto de rocío</td><td>{Math.round(climaHoy.dew_point)} °c</td></tr>
                    </tbody>
                </table>

                Sensación térmica: <br />
                <table className="table table-light table-striped table-bordered">
                    <tbody>
                        <tr><td style={{ width: '50%' }}>Mañana</td><td>{Math.round(climaHoy.feels_like.morn)} °c</td></tr>
                        <tr><td>Día</td><td>{Math.round(climaHoy.feels_like.day)} °c</td></tr>
                        <tr><td>Tarde</td><td>{Math.round(climaHoy.feels_like.eve)} °c</td></tr>
                        <tr><td>Noche</td><td>{Math.round(climaHoy.feels_like.night)} °c</td></tr>
                    </tbody>
                </table>
                <table className="table table-light table-striped table-bordered">
                    <tbody>
                        <tr><td style={{ width: '50%' }}>Amanecer</td><td>{amanecer}</td></tr>
                        <tr><td>Atardecer</td><td>{atardecer}</td></tr>
                        <tr><td>Salida de la luna</td><td>{salidaLuna}</td></tr>
                        <tr><td>Puesta de la luna</td><td>{ocultaLuna}</td></tr>
                        <tr><td>Luna</td><td><div><img className="mx-auto d-block" src={luna.a} width="30px" alt="" /></div>{luna.m}</td></tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Hoy
