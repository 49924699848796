import React from 'react';
import Dia from "./Dia.js";
import ResumenProximosDias from './ResumenProximosDias.js';
import ProximasHoras from "./ProximasHoras.js";


const Pronostico = (resultado) => {

    let resultadoClima = resultado.daily;
    let resultadoClimaHoras = resultado.hourly;

    let resumenProximosDias = resultadoClima.filter((dias, i) => i !== 0).map(dia => <ResumenProximosDias key={dia.sunrise} {...dia} />);

    let diasPronostico = resultadoClima.filter((dias, i) => i !== 0).map(dia => <Dia key={dia.dt} {...dia} />);

    let proximasHoras = resultadoClimaHoras.filter((horas, i) => (i !== 0) && (i % 3 === 1)).map(hora => <ProximasHoras key={hora.dt} {...hora} />);

    function mostrarOcultar(id) {
        var elemento = document.getElementById(id);
        elemento.classList.toggle("d-none");
    }

    return (
        <div className="col mt-2 p-3 border border-dark rounded">
            <h4 className="mb-4">Temperatura para los próximos días:</h4>
            <div className="row shadow-sm mb-3 pb-2 border rounded">
                {resumenProximosDias}
            </div>
            <div className="d-grid">
                <button type="button" className="btn btn-secondary mb-3 col-6 mx-auto" onClick={() => mostrarOcultar("proximasHorasDiv")}>Ver Próximas Horas</button>
            </div>
            <div id="proximasHorasDiv" className="table-responsive d-none">
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col" colSpan="2" className="text-center" >Fecha_y_Hora</th>
                            <th scope="col" className="text-center">Tiempo</th>
                            <th scope="col" className="text-center">Temp</th>
                            <th scope="col" className="text-center">Humedad</th>
                            <th scope="col" className="text-center">Lluvia</th>
                            <th scope="col" className="text-center">Viento_y_Ráfagas</th>
                            <th scope="col" className="text-center">UVI</th>
                        </tr>
                    </thead>
                    <tbody>
                        {proximasHoras}
                    </tbody>
                </table>
            </div>
            {diasPronostico}
        </div>
    )
}

export default Pronostico
