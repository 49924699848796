import React from 'react';
import imgToast from "../images/imgToast.png";
import {cerrarToast} from './ToastFunciones';


const Toast = () => {

    return (
        <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 11 }}>
            <div id="liveToast" className="toast hide align-items-center text-white bg-dark border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="d-flex">
                    <div className="toast-body">
                        <img src={imgToast} className="rounded me-2" alt="clima" width="30px" height="30px" />
                        <strong className="me-auto">Atención:{' '}<div id="estado"></div></strong>
                    </div>
                    <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={() => cerrarToast("estado", "liveToast")} ></button>
                </div>
            </div>
        </div>
    )
}

export default Toast
