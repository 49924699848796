
export function mostrarToast(idEstado, idToast, mensaje, idInput) {
    let estado = document.querySelector(`#${idEstado}`);
    let toast = document.querySelector(`#${idToast}`);
    estado.innerText = mensaje;
    toast.classList.remove(`hide`);
    toast.classList.add(`show`);
    if (idInput !== undefined) {
        let input = document.querySelector(`#${idInput}`);
        input.focus();
    }
}

export function cerrarToast(idEstado, idToast) {
    // alert("hola");
    let estado = document.querySelector(`#${idEstado}`);
    let toast = document.querySelector(`#${idToast}`);
    estado.innerText = ``;
    toast.classList.remove(`show`);
    toast.classList.add(`hide`);
}

export function cerrarToastAutom(milisegundos, idEstado, idToast) {
    setTimeout(cerrarToast, milisegundos, idEstado, idToast);
}

