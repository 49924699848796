import React from 'react'

const Header = () => {
    return (
        <div className="row mt-5">
            <div className="col">
                <h1 className="text-center">¿Cómo está el clima?</h1>
            </div>
        </div>
    )
}

export default Header
