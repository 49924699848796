import React from 'react';
import { useSelector } from 'react-redux';
import { calcDia, calcTime, gradosAPuntosCardinales, uvi, fasesDeLaLuna } from './EpochFunciones.js';

const Dia = (pronostico) => {

    var timezone = useSelector(state => state.timezone);

    let dia = calcDia(pronostico.dt, timezone);

    let descripcion = pronostico.weather[0].description.charAt(0).toUpperCase() + pronostico.weather[0].description.slice(1);

    let amanecer = calcTime(pronostico.sunrise, timezone);
    let atardecer = calcTime(pronostico.sunset, timezone);
    let salidaLuna = calcTime(pronostico.moonrise, timezone);
    let ocultaLuna = calcTime(pronostico.moonset, timezone);

    let direccionViento = gradosAPuntosCardinales(pronostico.wind_deg);

    let indiceUV = uvi(pronostico.uvi);

    let luna = fasesDeLaLuna(pronostico.moon_phase);

    return (
        <div className="row shadow-lg mb-3">
            <div className="col border rounded m-1 p-2">
                <div className="row shadow-sm mb-3">
                    <div className="col d-flex align-items-center">
                        <h3 className="mx-auto">{dia}</h3>
                    </div>
                    <div className="col d-flex align-items-center">
                        <h5 className="mx-auto text-danger">{descripcion}</h5>
                    </div>
                    <div className="col d-flex align-items-center">
                        <img className="mx-auto" src={`https://openweathermap.org/img/w/${pronostico.weather[0].icon}.png`} width="100px" height="auto" alt="" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6">
                        Temperatura:<br />
                        <table className="table table-light table-striped table-bordered">
                            <tbody>
                                <tr><td style={{ width: '50%' }}>Min / Max</td><td>{Math.round(pronostico.temp.min)} / {Math.round(pronostico.temp.max)} °c</td></tr>
                            </tbody>
                        </table>
                        Sensación térmica: <br />
                        <table className="table table-light table-striped table-bordered">
                            <tbody>
                                <tr><td style={{ width: '50%' }}>Mañana</td><td>{Math.round(pronostico.feels_like.morn)} °c</td></tr>
                                <tr><td>Día</td><td>{Math.round(pronostico.feels_like.day)} °c</td></tr>
                                <tr><td>Tarde</td><td>{Math.round(pronostico.feels_like.eve)} °c</td></tr>
                                <tr><td>Noche</td><td>{Math.round(pronostico.feels_like.night)} °c</td></tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="col-12 col-md-6">
                        <table className="table table-light table-striped table-bordered">
                            <tbody>
                                <tr><td style={{ width: '50%' }}>Viento</td><td>{Math.round(pronostico.wind_speed * 3.6)} km/h {direccionViento}</td></tr>
                                <tr><td>Ráfagas</td><td>{Math.round(pronostico.wind_gust * 3.6)} km/h</td></tr>
                                <tr><td>Humedad</td><td>{Math.round(pronostico.humidity)} %</td></tr>
                                <tr><td>Presión</td><td>{Math.round(pronostico.pressure)} hPa</td></tr>
                                <tr><td>Chance de lluvia</td><td>{Math.ceil(pronostico.pop)} %</td></tr>
                                <tr><td>Indice UV</td><td>{indiceUV}</td></tr>
                                <tr><td>Punto de rocío</td><td>{Math.round(pronostico.dew_point)} °c</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <table className="table table-light table-striped table-bordered">
                    <tbody>
                        <tr><td style={{ width: '50%' }}>Amanecer</td><td>{amanecer}</td></tr>
                        <tr><td>Atardecer</td><td>{atardecer}</td></tr>
                        <tr><td>Salida de la luna</td><td>{salidaLuna}</td></tr>
                        <tr><td>Puesta de la luna</td><td>{ocultaLuna}</td></tr>
                        <tr><td>Luna</td><td><div><img className="mx-auto d-block" src={luna.a} width="30px" alt="" /></div>{luna.m}</td></tr>
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default Dia
