import initialState from "../store/initialState.js";


const reducer = (state = initialState, action) => {
    // console.log(state, action);
    switch (action.type) {
        case "BUSCAR_CIUDAD":
            return {
                ...state,
                ciudadBuscada: action.ciudad,
                resultadoClima: action.payload,
                pais: action.pais,
                posiblesCiudades: [],
                timezone: action.payload.timezone_offset
            };

        case "CARGAR_DEFAULT":
            return {
                ...state,
                resultadoClima: action.payload,
                timezone: action.payload.timezone_offset
            };

        case "BUSCAR_COORDENADAS":
            return {
                ...state,
                posiblesCiudades: action.payload
            };

        default:
            return state;
    }
}

export default reducer
