import React from 'react'

const Footer = () => {
    return (
        <div className="row fixed-bottom" id="footer">
            <div className="col bg-dark text-white">
                <p className="text-center">2021 - LoreG</p>
            </div>
        </div>
    )
}

export default Footer
