import React from 'react';
import { useSelector } from 'react-redux';
import { calcDia, calcDiaNum, calcTime, gradosAPuntosCardinales } from './EpochFunciones.js';

const ProximasHoras = (pronosticoHoras) => {

    var timezone = useSelector(state => state.timezone);

    let hora = calcTime(pronosticoHoras.dt, timezone);
    let dia = calcDia(pronosticoHoras.dt, timezone).slice(0, 3);
    let diaNum = calcDiaNum(pronosticoHoras.dt, timezone);
    let direccionViento = gradosAPuntosCardinales(pronosticoHoras.wind_deg);


    return (
        <>
            <tr>
            <td >{dia} {diaNum}</td>
            <td className="text-end">{hora}</td>
            <td><img src={`https://openweathermap.org/img/w/${pronosticoHoras.weather[0].icon}.png`} width="auto" height="auto" alt="" /></td>
            <td>{Math.round(pronosticoHoras.temp)} °c</td>
            <td>{Math.round(pronosticoHoras.humidity)} %</td>
            <td>{Math.round(pronosticoHoras.pop)} %</td>
            <td>{Math.round(pronosticoHoras.wind_speed * 3.6)} km/h {direccionViento}<br/>
            Raf: {Math.round(pronosticoHoras.wind_gust * 3.6)} km/h</td>
            <td>{Math.ceil(pronosticoHoras.uvi)}</td>
        </tr>
        </>
    )
}

export default ProximasHoras
