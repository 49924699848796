import React from 'react';
import { useSelector } from 'react-redux';
import Hoy from "./Hoy.js";
import Pronostico from "./Pronostico.js";
import Ciudad from './Ciudad.js';

const Contenido = () => {

    let resultadoClima = useSelector(state => state.resultadoClima);
    let posiblesCiudades = useSelector(state => state.posiblesCiudades);

    let mapCiudades = posiblesCiudades.map(ciudad => <Ciudad key={ciudad.lat} {...ciudad} />);

    // console.log("resultadoClima:");
    // console.log(resultadoClima);
    // console.log("posiblesCiudades:");
    // console.log(posiblesCiudades);

    return (
        <div id="contenido">

            {posiblesCiudades.length ? <>
                <div className="row m-2">
                    <span className="mt-3 mb-2">Por favor, seleccione una ciudad:</span> <br />
                    <table className="table table-light table-striped table-bordered table-hover">
                        <tbody>
                            {mapCiudades}
                        </tbody>
                    </table>
                </div>
            </> : resultadoClima.daily ? <>
                <div className="row m-2">
                    <Hoy key={2} {...resultadoClima} />
                    <Pronostico key={3} {...resultadoClima} />
                </div>
            </> : ""}


        </div>
    )
}

export default Contenido
