import "./css/bootstrap.min.css";
import './App.css';
import Header from "./componentes/Header";
import Buscador from "./componentes/Buscador.js";
import Contenido from "./componentes/Contenido.js";
import Footer from "./componentes/Footer.js";
import { Provider } from "react-redux";
import store from "./store/store";
import Toast from "./componentes/Toast.js"

function App() {
  return (
    <Provider store={store}>
      <Toast />
      <div className="container">
        <Header />
        <Buscador />
        <Contenido />
        <Footer />
      </div>
    </Provider>
  );
}

export default App;
