import React from 'react';
import { useSelector } from 'react-redux';
import { calcDia } from './EpochFunciones.js';

const ResumenProximosDias = (pronostico) => {

    var timezone = useSelector(state => state.timezone);
    let dia = calcDia(pronostico.dt, timezone).slice(0, 3);

    return (
        <div className="col m-1 bg-light">
            <div className="text-center">{dia} </div>
            <div className="text-center"><img src={`https://openweathermap.org/img/w/${pronostico.weather[0].icon}.png`} width="auto" height="auto" alt="" /></div>
            <div className="text-center">{Math.round(pronostico.temp.min)} / {Math.round(pronostico.temp.max)} °c</div>
        </div>
    )
}

export default ResumenProximosDias
